<template>
    <div id="pageNotFound">
        <PageTitle title="Thank You"/>
        <div>
            <p v-html="content">
            </p>
            <hr>
            <router-link to="/" id="goHome">
                <button>HOMEPAGE</button>
            </router-link>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "PageNotFound",
    components: {
        PageTitle
    },
    data(){
        return{
            content: "You submission has been successful, thank you!",
        }
    },
    metaInfo: {
        title: 'Page Nout Found',
        titleTemplate: '%s | WALTERS | PLAXEN',
        meta: [{
            name: 'description', 
            content: "WALTERS | PLAXEN"
        }]
    },
}
</script>

<style lang="scss" scoped>
#pageNotFound{
    text-align: center;
}
p{
    margin-top: 15%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    line-height: 2.5rem;
}
hr{
    border:0;
    height: 2px;
    width: 3rem;
    margin-bottom: 2rem;
}

#goHome{
    button{
        color: #fff;
        font-size: 1rem;
        letter-spacing: 3px;
        padding: 0.5rem 2rem;
        margin-bottom: 20%;
        font-weight: 600;
        color: #fff;
        background: #bf9b30;
        border: 1px solid #bf9b30;
        &:hover{
            cursor: pointer;
        }
    }
}
</style>